import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type MouseEvent,
} from "react";

import { Link as LinkIcon } from "@mui/icons-material";
import { Button, Stack, Tooltip, type ButtonProps } from "@mui/material";
import { noop } from "lodash-es";
import { useClickAway } from "react-use";
import { Editor, Range } from "slate";
import { useSlateSelection, useSlateStatic } from "slate-react";

import { useToolbarEditModeContext } from "@ll-web/features/textEditor/contexts/ToolbarEditModeContext";
import type { LinkElement } from "@ll-web/features/textEditor/types";
import { getSelectionText } from "@ll-web/features/textEditor/utils/editor";

type LinkButtonProps = ButtonProps;

export const LinkButton = (props: LinkButtonProps) => {
  const { setEditMode, editMode } = useToolbarEditModeContext();
  const editor = useSlateStatic();
  const selection = useSlateSelection();
  const selectionStr =
    selection && !Range.isCollapsed(selection) && JSON.stringify(selection);
  const [canAddLink, setCanAddLink] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickLinkButton = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      const text = getSelectionText(editor);
      if (!text) {
        return;
      }

      const [currentSelectionNode] = editor.selection
        ? Editor.node(editor, editor.selection)
        : [null];

      if ((currentSelectionNode as LinkElement)?.url) {
        setEditMode("editLink");

        return;
      }

      setEditMode("addLink");
    },
    [editor, setEditMode],
  );

  useClickAway(containerRef, canAddLink ? () => setCanAddLink(false) : noop);

  useEffect(() => {
    setCanAddLink(!!(editMode === "hover" && selectionStr));
  }, [editMode, selectionStr]);

  return (
    <Tooltip
      title={
        canAddLink ? "" : "You need to select text before you add the link"
      }
      placement="top-start"
    >
      <Stack width="fit-content" ref={containerRef}>
        <Button
          color="inherit"
          variant="contained"
          size="small"
          onMouseUp={(e) => e.stopPropagation()}
          onClick={handleClickLinkButton}
          disabled={!canAddLink}
          {...props}
          sx={{
            minWidth: "auto",
            width: "fit-content",
            "& .MuiButton-icon": {
              marginLeft: 0,
            },
          }}
        >
          <LinkIcon />
        </Button>
      </Stack>
    </Tooltip>
  );
};

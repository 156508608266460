import { useCallback, useMemo, useState } from "react";

import type { ButtonProps } from "@mui/material";

import { assertDefined } from "@ll-web/utils/types/types";

import {
  RemoveConfirmationDialog,
  type RemoveConfirmationDialogProps,
} from "./RemoveConfirmationDialog";

type UseRemoveConfirmationDialogArgs<T = void> = {
  onRemove: (arg?: T) => Promise<void>;
  onCancel?: VoidFunction;
  dialogProps: Omit<
    RemoveConfirmationDialogProps,
    "onRemove" | "open" | "close"
  > & { confirmButtonColor?: ButtonProps["color"] };
};

export const useRemoveConfirmationDialog = ({
  onRemove,
  onCancel,
  dialogProps,
}: UseRemoveConfirmationDialogArgs) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const openRemoveConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(true);
  }, []);

  const handleConfirmRemove = useCallback(async () => {
    assertDefined(onRemove);
    await onRemove();

    setIsConfirmationModalOpen(false);
  }, [onRemove]);

  const handleCancelRemove = useCallback(() => {
    setIsConfirmationModalOpen(false);
    onCancel?.();
  }, [onCancel]);

  const removeConfirmationDialogNode = useMemo(() => {
    return (
      <RemoveConfirmationDialog
        onRemove={handleConfirmRemove}
        open={isConfirmationModalOpen}
        close={handleCancelRemove}
        {...dialogProps}
      />
    );
  }, [
    handleConfirmRemove,
    isConfirmationModalOpen,
    handleCancelRemove,
    dialogProps,
  ]);

  return useMemo(
    () => ({
      openRemoveConfirmationModal,
      removeConfirmationDialogNode,
    }),
    [openRemoveConfirmationModal, removeConfirmationDialogNode],
  );
};

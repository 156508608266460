import { Pages } from "@ll-web/core/router/pages";
import type { WizardSteps } from "@ll-web/features/projectWizard/consts/wizardSteps";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { isProposalFlow } from "@ll-web/features/proposals/utils/isProposalFlow";

// eslint-disable-next-line max-params
export function makeWizardPath(
  projectId?: string,
  flowType: WizardFlowType = WizardFlowType.Generate,
  step?: WizardSteps,
  proposalId?: string,
): string {
  if (isProposalFlow(flowType) && proposalId) {
    if (!projectId) {
      return Pages.ProposalDefaultProjectWizard.replace(
        ":proposalId",
        proposalId,
      );
    }

    return Pages.ProposalProjectWizard.replace(":proposalId", proposalId)
      .replace(":id", projectId)
      .replace(":flowType", flowType)
      .replace(":step", step ?? "");
  }

  return Pages.ProjectWizard.replace(":id", projectId ?? "")
    .replace(":flowType", flowType)
    .replace(":step", step ?? "");
}

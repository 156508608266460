import { useCallback } from "react";

import type { CommentMessageType } from "@ll-web/features/projectComments/types";
import type {
  TextEditorComment,
  TextEditorCommentsPluginInternalConfig,
  TextEditorEditCommentMode,
} from "@ll-web/features/textEditor/comments/types";
import type { TextEditorEditMode } from "@ll-web/features/textEditor/types";
import { assertDefined } from "@ll-web/utils/types/types";

import { CommentEditor } from "./CommentEditor";

type CommentEditorInternalWrapperProps = {
  threadId?: string;
  commentsConfig: TextEditorCommentsPluginInternalConfig;
  onCancel: () => void;
  onFinish: () => void;
  onEditModeChange: (editMode: TextEditorEditMode) => void;
  onThreadLoad?: () => void;
  onAddCommentAnchor?: (data: TextEditorComment) => void;
  onRemoveCommentAnchor: (data: TextEditorComment) => void;
  onUpdateCommentAnchor: (data: TextEditorComment) => void;
  editMode: TextEditorEditCommentMode;
  quote: string;
  isReadOnly?: boolean;
};

export const CommentEditorInternalWrapper = ({
  commentsConfig: _commentsConfig,
  onEditModeChange,
  onFinish,
  onAddCommentAnchor,
  quote,
  ...props
}: CommentEditorInternalWrapperProps) => {
  const { onAdd, ...commentsConfig } = _commentsConfig;

  const handleEditStart = useCallback(() => {
    onEditModeChange("editComment");
  }, [onEditModeChange]);

  const handleCreateThread = useCallback(
    async ({
      message,
    }: Pick<CommentMessageType, "message"> & {
      userId?: string;
    }) => {
      assertDefined(onAddCommentAnchor);

      const commentData = {
        message,
        quote,
      };
      const comment = await onAdd(commentData);
      if (comment) {
        const { id } = comment;
        onAddCommentAnchor({ id });
      }
      onFinish();
    },
    [quote, onAddCommentAnchor, onAdd, onFinish],
  );

  const handleAddReply = useCallback(
    async (
      data: Pick<CommentMessageType, "threadId" | "message"> & {
        userId?: string;
      } & {
        userId?: string;
      },
    ) => {
      await onAdd(data);
    },
    [onAdd],
  );

  return (
    <CommentEditor
      {...commentsConfig}
      {...props}
      onEditStart={handleEditStart}
      onAddThread={handleCreateThread}
      onAddReply={handleAddReply}
      onFinish={onFinish}
      onEditModeChange={onEditModeChange}
    />
  );
};

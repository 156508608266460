import { ProjectStyleEnum } from "@ll-web/config/featureFlags/consts";
import { type User } from "@ll-web/features/auth/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { linkToCallSheet } from "@ll-web/features/projectSpace/pages/CallSheets/utils/linkToCallSheet";
import { linkToCreativeDeck } from "@ll-web/features/projectSpace/pages/CreativeDeck/utils/linkToCreativeDeck";
import type { ReviewEmailTemplateArgs } from "@ll-web/features/reviews/types";
import {
  emailGreeting,
  emailSignature,
  htmlEmailBody,
} from "@ll-web/utils/helpers/emails";
import { getPersonFullName } from "@ll-web/utils/helpers/helpers";
import { propertiesDefined, type Nullable } from "@ll-web/utils/types/types";

export const wizardGenerateExternalLegacyEmail = ({
  project,
  activeUser,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  project?: ProjectWithDeliverables;
  activeUser: User;
}>) => {
  const projectName = project?.title;
  const requiredBodyParams = {
    projectStyle: project?.style,
    projectId: project?.id,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `Creative Brief edited/submitted for review${projectName ? ` for ${projectName}` : ""}`,
    body: emailBody({
      requiredBodyParams,
      activeUser,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient },
  activeUser,
}: ReviewEmailTemplateArgs<
  {
    requiredBodyParams: {
      projectStyle: Nullable<string>;
      projectId: Nullable<string>;
    };
  } & {
    activeUser: User;
  }
>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return "";
  }

  const creativeDeckLink = linkToCreativeDeck(requiredBodyParams.projectId);
  const callSheetLink =
    requiredBodyParams.projectStyle === ProjectStyleEnum.DocStyle
      ? linkToCallSheet(requiredBodyParams.projectId)
      : null;

  const creativeDeckLinkText = `
Creative Deck`;
  const callSheetLinkText =
    requiredBodyParams.projectStyle === ProjectStyleEnum.DocStyle
      ? "Call Sheet"
      : "";

  return htmlEmailBody(`${emailGreeting("Hi", defaultRecipient?.user?.firstName)}
  <br><br>
I've completed the Creative Brief for my video project. Looking forward to discussing the details with you on our upcoming call.
<br><br>
<a href="${creativeDeckLink}">${creativeDeckLinkText}</a>
${callSheetLink && `<br><a href="${callSheetLink}">${callSheetLinkText}</a>`}
<br><br>
${emailSignature("Thanks", getPersonFullName(activeUser))}`);
};

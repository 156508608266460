import { AccountType } from "@ll-web/features/auth/types";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import {
  type ReviewEmailTemplateArgs,
  type ReviewEmailTemplateUsers,
} from "@ll-web/features/reviews/types";
import { filterBrandUsersByType } from "@ll-web/features/users/utils/filterUsers";
import { defined } from "@ll-web/utils/types/types";

const brandUserTypes: (keyof ReviewEmailTemplateUsers["brandUsers"])[] = [
  AccountType.Producer,
  AccountType.Creative,
  AccountType.AccountExecutive,
  AccountType.Editor,
  BrandRoleEnum.Owner,
];

export const getReviewEmailUsers = ({
  users,
  recipientsTypes,
}: {
  users: BrandUserWithProfile[];
  recipientsTypes: typeof brandUserTypes;
}): ReviewEmailTemplateArgs => {
  const brandUsers = brandUserTypes.reduce(
    (acc, brandUserType) => {
      if (brandUserType === BrandRoleEnum.Owner) {
        return {
          ...acc,
          [brandUserType]:
            filterBrandUsersByType({
              users,
              type: AccountType.User,
              role: brandUserType,
            })?.[0] ?? null,
        };
      }

      return {
        ...acc,
        [brandUserType]: filterBrandUsersByType({
          users,
          type: brandUserType,
        })?.[0],
      };
    },
    {} as Record<(typeof brandUserTypes)[number], BrandUserWithProfile | null>,
  );

  const recipients = recipientsTypes.map((userType) => {
    return brandUsers[userType];
  });

  return {
    reviewEmailTemplateUsers: {
      brandUsers,
      recipientUserIds: recipients.map((user) => user?.userId).filter(defined),
      defaultRecipient: recipients[0],
    },
  };
};

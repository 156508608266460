import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ComponentProps,
} from "react";

import { alpha, type BoxProps, type SxProps, type Theme } from "@mui/material";
import { Editor, Range, Text, type NodeEntry } from "slate";
import { useSlateSelection, useSlateStatic, type Editable } from "slate-react";

import {
  TextEditorPluginsEnum,
  type TextEditorConfig,
  type TextEditorEditMode,
} from "@ll-web/features/textEditor/types";
import { defined, typedEntries } from "@ll-web/utils/types/types";

export const activeElementSelectionClassName = "activeElementSelection";

const pluginsDecoratingSelection: TextEditorPluginsEnum[] = [
  TextEditorPluginsEnum.Comments,
];

const shouldEnableDecoration = (config?: TextEditorConfig) => {
  return typedEntries(config?.plugins || {}).some(
    ([plugin, enabled]) =>
      enabled && pluginsDecoratingSelection.includes(plugin),
  );
};

const modesDecoratingLastSelection: TextEditorEditMode[] = [
  "viewComment",
  "newComment",
  "editComment",
];

type useDecorateSelectionArgs = {
  config?: TextEditorConfig;
  editMode: TextEditorEditMode;
  isWholeTextHighlighted?: boolean;
};

export const useDecorateSelection = ({
  config,
  editMode,
  isWholeTextHighlighted,
}: useDecorateSelectionArgs) => {
  const isDecoratingEnabled = shouldEnableDecoration(config);
  const isDecoratingLastSelected =
    isDecoratingEnabled && modesDecoratingLastSelection.includes(editMode);

  const editor = useSlateStatic();
  const selection = useSlateSelection();

  const [lastActiveSelection, setLastActiveSelection] =
    useState<Range | null>();

  const decorate = useCallback(
    // this is used to decorate i.e. style the selection without it being a separate node when hover/comment toolbar is open
    ([node, path]: NodeEntry): Range[] => {
      if (!isDecoratingLastSelected) {
        return [];
      }

      if (Text.isText(node) && lastActiveSelection !== null) {
        if (!defined(lastActiveSelection)) {
          return [];
        }

        const intersection = Range.intersection(
          lastActiveSelection,
          Editor.range(editor, path),
        );

        if (intersection === null) {
          return [];
        }

        const range: Range = {
          keepSelected: true,
          ...intersection,
        };

        return [range];
      }

      return [];
    },
    [editor, lastActiveSelection, isDecoratingLastSelected],
  );

  const editableContainerProps: BoxProps = useMemo(() => {
    if (isWholeTextHighlighted) {
      return { sx: selectionSx };
    }

    if (!isDecoratingEnabled) {
      return {};
    }

    return {
      sx: {
        // override default app selection color
        [`& :not(.${activeElementSelectionClassName}) ::selection`]: {
          ...selectionSx,
        },
        // allow active selected element to be styled on their own
        [`& .${activeElementSelectionClassName} *`]: {
          background: "unset",
        },
        [`& .${activeElementSelectionClassName} ::selection`]: {
          background: "transparent",
        },
      },
    };
  }, [isDecoratingEnabled, isWholeTextHighlighted]);

  const editableProps: ComponentProps<typeof Editable> = useMemo(
    () =>
      isDecoratingLastSelected
        ? {
            decorate,
          }
        : {},
    [decorate, isDecoratingLastSelected],
  );

  useEffect(() => {
    if (isDecoratingLastSelected) {
      setLastActiveSelection(selection);
    }
  }, [isDecoratingLastSelected, selection]);

  return useMemo(() => {
    return {
      decorate,
      editableProps,
      editableContainerProps,
    };
  }, [decorate, editableProps, editableContainerProps]);
};

export const selectionSx: SxProps<Theme> = {
  background: (theme) =>
    alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
};

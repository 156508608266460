import { memo, useMemo, type ComponentProps } from "react";

import { Box, Typography, type TypographyProps } from "@mui/material";
import { Editable } from "slate-react";

import { useToolbarEditModeContext } from "@ll-web/features/textEditor/contexts/ToolbarEditModeContext";
import { useDecorateSelection } from "@ll-web/features/textEditor/hooks/useDecorateSelection";
import type { TextEditorConfig } from "@ll-web/features/textEditor/types";

import { makeElement } from "./makeElement";
import { makeLeaf } from "./makeLeaf";

const editableClassName = "commentDecoratedEditable";

type EditableDecoratedProps = ComponentProps<typeof Editable> & {
  config?: TextEditorConfig;
  textNodeProps?: TypographyProps;
  isWholeTextHighlighted?: boolean;
};

export const EditableDecorated = memo(
  ({
    config,
    isWholeTextHighlighted,
    textNodeProps,
    ...props
  }: EditableDecoratedProps) => {
    const { editMode, editorReadOnlyOverride } = useToolbarEditModeContext();

    const decorateSelection = useDecorateSelection({
      config,
      editMode,
      isWholeTextHighlighted,
    });

    const memoizedElement = useMemo(
      () => makeElement(textNodeProps),
      [textNodeProps],
    );
    const memoizedLeaf = useMemo(
      () => makeLeaf(textNodeProps),
      [textNodeProps],
    );

    return (
      <Box
        {...decorateSelection.editableContainerProps}
        sx={{
          ...decorateSelection.editableContainerProps.sx,
          width: "100%",
        }}
      >
        <Editable
          style={{
            outline: "none",
            overflowWrap: "anywhere",
          }}
          renderElement={memoizedElement}
          renderLeaf={memoizedLeaf}
          renderPlaceholder={({ attributes }) => (
            <Typography
              {...attributes}
              sx={{
                fontStyle: "italic",
                opacity: "1!important",
              }}
            >
              {props.placeholder}
            </Typography>
          )}
          {...props}
          {...decorateSelection.editableProps}
          readOnly={editorReadOnlyOverride ?? props.readOnly}
          className={editableClassName}
        />
      </Box>
    );
  },
);

EditableDecorated.displayName = "EditableDecorated";

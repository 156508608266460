import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  type DialogProps,
} from "@mui/material";

import { Spinner } from "@ll-web/components/Spinner/Spinner";
import type { User } from "@ll-web/features/auth/types";
import type {
  ReviewEmailData,
  ReviewModalPassedProps,
} from "@ll-web/features/reviews/types";
import type { Nullable } from "@ll-web/utils/types/types";

import { NotifyModalForm } from "./NotifyModalForm/NotifyModalForm";

type NotifyForReviewModalProps = DialogProps &
  ReviewModalPassedProps & {
    defaultReceiversData: User[];
    brandId: Nullable<string>; // undefined during loading;
    isLoading: boolean;
    close: () => void;
    onSubmitForReview: (payload: ReviewEmailData) => Promise<void>;
  };

export const NotifyForReviewModal = ({
  defaultReceiversData,
  brandId,
  isLoading,
  reviewEmailData,
  warningContent,
  titleContent,
  headingContent,
  submitButtonContent = "Submit for Review",
  close,
  onSubmitForReview,
  ...dialogProps
}: NotifyForReviewModalProps) => {
  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle sx={{ py: 2, px: 3 }}>{titleContent}</DialogTitle>
      {warningContent && (
        <Alert severity="warning" sx={{ mx: 3 }}>
          {warningContent}
        </Alert>
      )}
      <DialogContent
        sx={{
          py: 0,
        }}
      >
        {isLoading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            height="100%"
            width="100%"
          >
            <Spinner size={64} />
          </Stack>
        ) : (
          <NotifyModalForm
            defaultReceiversData={defaultReceiversData}
            brandId={brandId!}
            reviewEmailData={reviewEmailData}
            headingContent={headingContent}
            submitButtonContent={submitButtonContent}
            onSubmitForReview={onSubmitForReview}
            onClose={close}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

import type { UseFormReturn } from "react-hook-form";

import type { ProjectStyleEnum } from "@ll-web/config/featureFlags/consts";
import { basicInfoFields } from "@ll-web/features/internalProjects/projectForm/basicInfo/basicInfoSchema";
import { makeBasicInfoName } from "@ll-web/features/internalProjects/projectForm/basicInfo/makeBasicInfoName";
import {
  PROJECT_FIELDS,
  type ProjectFormValues,
} from "@ll-web/features/internalProjects/projectForm/projectFormSchema";

export function useProjectFormAnalytics(
  methods: UseFormReturn<ProjectFormValues>,
) {
  const id = methods.watch(PROJECT_FIELDS.id) as string;
  const title = methods.watch(
    makeBasicInfoName({ field: basicInfoFields.title }),
  ) as string;
  const style = methods.watch(
    makeBasicInfoName({ field: basicInfoFields.style }),
  ) as ProjectStyleEnum;

  return { id, title, style };
}

import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useMediaRemote, useMediaStore } from "@vidstack/react";
import * as yup from "yup";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { ScopedThemeProvider } from "@ll-web/core/theme/GlobalThemeProvider";
import type { CommentMessageValues } from "@ll-web/features/projectComments/components/CommentEditMessage";
import { useCommentNumberRegister } from "@ll-web/features/projectComments/contexts/CommentNumberRegisterProvider";
import { ProjectCommentSourceEnum } from "@ll-web/features/projectComments/enums";
import { useProjectComments } from "@ll-web/features/projectComments/hooks/useProjectComments";
import type {
  ProjectComment,
  ProjectCommentMetadata,
} from "@ll-web/features/projectComments/types";
import { useGetProjectById } from "@ll-web/features/projects/async/useProjectsQueries";
import { CommentsOverlayClickTarget } from "@ll-web/features/videoPlayer/comments/components/CommentsOverlayClickTarget";
import { NewVideoThreadBubble } from "@ll-web/features/videoPlayer/comments/components/NewVideoThreadBubble";
import { VideoThread } from "@ll-web/features/videoPlayer/comments/components/VideoThread";
import { useGetOnVideoThreads } from "@ll-web/features/videoPlayer/comments/hooks/useGetOnVideoThreads";
import { useGetVideoThreadIndexes } from "@ll-web/features/videoPlayer/comments/hooks/useGetVideoThreadIndexes";
import type { CommentsPluginConfig } from "@ll-web/features/videoPlayer/comments/types";
import { useVideoPlayerRef } from "@ll-web/features/videoPlayer/contexts/VideoPlayerRefContext";
import { getPlayer } from "@ll-web/features/videoPlayer/helpers/videoPlayer";
import { useRoundedTime } from "@ll-web/features/videoPlayer/hooks/useRoundedTime";
import { useTypedSearchParams } from "@ll-web/utils/hooks/useTypedSearchParams";
import { assertDefined } from "@ll-web/utils/types/types";

type CommentsVideoOverlayProps = {
  pluginConfig: CommentsPluginConfig;
};

export const CommentsVideoOverlay = memo(
  ({ pluginConfig }: CommentsVideoOverlayProps) => {
    const { videoPlayerRef } = useVideoPlayerRef();
    const { canPlay } = useMediaStore(videoPlayerRef);
    const mediaRemote = useMediaRemote(videoPlayerRef);
    const { roundedTime } = useRoundedTime();

    const { params, updateParams } = useTypedSearchParams(
      yup.object({
        commentId: yup.string().optional(),
      }),
      undefined,
      { stripUnknown: false },
    );
    const activeCommentId = params.commentId;

    const [newThread, setNewThread] = useState<Required<
      Pick<ProjectComment["target"], "position">
    > | null>(null);

    const { threads } = useGetOnVideoThreads({
      projectId: pluginConfig.projectId,
      deliverableId: pluginConfig.deliverableId,
      timestamp: roundedTime,
    });

    const { indexes } = useGetVideoThreadIndexes({
      projectId: pluginConfig.projectId,
      deliverableId: pluginConfig.deliverableId,
    });

    const { setCommentNumberRegister } = useCommentNumberRegister();
    useEffect(() => {
      setCommentNumberRegister(indexes);
    }, [indexes, setCommentNumberRegister]);

    const { data: project } = useGetProjectById({ id: pluginConfig.projectId });

    const metadata = useMemo<ProjectCommentMetadata>(
      () => ({
        analyticsMetadata: {
          projectId: pluginConfig.projectId,
          projectName: project?.title ?? "",
          videoStyle: project?.style ?? "",
          page: "Video Review",
        },
        target: {
          ...threads[0]?.target,
          source: ProjectCommentSourceEnum.DeliverableVideo,
          deliverableId: pluginConfig.deliverableId,
        },
      }),
      [
        pluginConfig.deliverableId,
        pluginConfig.projectId,
        project?.style,
        project?.title,
        threads,
      ],
    );
    const { onAdd } = useProjectComments({
      metadata,
    });

    const handleStartAddThread = useCallback(
      ({ position }: { position: [number, number] }) => {
        mediaRemote.pause();
        setNewThread({
          position,
        });
        updateParams({ commentId: undefined });
      },
      [updateParams, mediaRemote],
    );

    const handleAddThread = useCallback(
      async ({ message }: CommentMessageValues) => {
        assertDefined(newThread, "draftComment");
        activityTracker.log({
          type: ActivityType.WizardOutputLeftComment,
          metadata: metadata.analyticsMetadata,
        });
        const player = getPlayer(videoPlayerRef);
        const timestamp = player.currentTime;
        await onAdd({
          message,
          target: {
            position: newThread.position,
            timestamp,
          },
        });
        setNewThread(null);
      },
      [newThread, metadata.analyticsMetadata, videoPlayerRef, onAdd],
    );

    const handleCancelAddThread = useCallback(() => {
      setNewThread(null);
    }, []);

    if (!canPlay) {
      return null;
    }

    return (
      <ScopedThemeProvider variant="light">
        <Box
          sx={{
            position: "absolute",
            inset: 0,
          }}
          className="comments-overlay"
        >
          {pluginConfig.canAddNewDeliverableComments && (
            <CommentsOverlayClickTarget onAddTrigger={handleStartAddThread} />
          )}

          {threads?.map((thread) => (
            <VideoThread
              metadata={metadata}
              key={thread.id}
              threadId={thread.id}
              isOpen={activeCommentId === thread.id}
              setIsOpen={(value) =>
                updateParams({ commentId: value ? thread.id : undefined })
              }
              sx={{
                position: "absolute",
                left: `${thread.target.position![0] * 100}%`,
                top: `${thread.target.position![1] * 100}%`,
              }}
            />
          ))}

          {newThread && (
            <NewVideoThreadBubble
              cancel={handleCancelAddThread}
              onAddThread={handleAddThread}
              sx={{
                position: "absolute",
                left: `${newThread.position[0] * 100}%`,
                top: `${newThread.position[1] * 100}%`,
              }}
            />
          )}
        </Box>
      </ScopedThemeProvider>
    );
  },
);
CommentsVideoOverlay.displayName = "CommentsVideoOverlay";

import { useParams } from "react-router-dom";

import type { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { isProposalFlow } from "@ll-web/features/proposals/utils/isProposalFlow";

export const useIsProposalWizardFlow = () => {
  const { flowType: flowTypeFromUrl } = useParams() as {
    flowType?: WizardFlowType;
    proposalId?: string;
  };

  return flowTypeFromUrl && isProposalFlow(flowTypeFromUrl);
};

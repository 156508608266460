import { type PropsWithChildren } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { AnalyticsProvider } from "@ll-web/core/analytics/AnalyticsContext";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import { useActiveProposalProject } from "@ll-web/features/projectWizard/hooks/useActiveProposalProjects";
import Status404 from "@ll-web/features/statusPages/pages/Status404";

const ActiveProposalProjectsContainer = ({ children }: PropsWithChildren) => {
  const { activeProjects, isPending } = useActiveProposalProject();

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProjects.length) {
    return <Status404 />;
  }

  return (
    <AnalyticsProvider metadata={mapProjectToAnalytics(activeProjects[0])}>
      {children}
    </AnalyticsProvider>
  );
};

export default ActiveProposalProjectsContainer;

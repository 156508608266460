import { Typography, type TypographyProps } from "@mui/material";
import type { RenderElementProps } from "slate-react";

import { CommentElement } from "@ll-web/features/textEditor/comments/components/CommentElement";
import { LinkElement } from "@ll-web/features/textEditor/richtext/components/LinkElement";
import { TextEditorElementEnum } from "@ll-web/features/textEditor/types";

export const Element = ({
  textNodeProps,
  ...props
}: RenderElementProps & { textNodeProps?: TypographyProps }) => {
  const { element, attributes, children } = props;

  switch (element.type) {
    case TextEditorElementEnum.Comment:
      return <CommentElement {...props} textNodeProps={textNodeProps} />;
    case TextEditorElementEnum.Link:
      return (
        <LinkElement
          {...props}
          element={element}
          textNodeProps={textNodeProps}
        />
      );

    default:
      return (
        <Typography component="p" {...(textNodeProps ?? {})} {...attributes}>
          {children}
        </Typography>
      );
  }
};

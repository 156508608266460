import { useParams, useSearchParams } from "react-router-dom";

import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { assertDefined } from "@ll-web/utils/types/types";

import { useProject } from "./useProject";

export const useActiveProject = (): {
  activeProject: ProjectWithDeliverables;
} => {
  const projectId = useActiveProjectId();

  return useProject(projectId);
};

export const useActiveProjectId = (): string => {
  const pathParams = useParams();
  const [queryParams] = useSearchParams();

  const projectId = pathParams.id ?? queryParams.get("projectId");
  assertDefined(projectId, "Missing active project id in path or query");

  return projectId;
};

import { ActivityType } from "@ll-web/core/analytics/events";
import { AccountType, type User } from "@ll-web/features/auth/types";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { wizardGenerateInternalEmail } from "@ll-web/features/projectWizard/reviews/emailTemplates/wizardGenerateInternalEmail";
import {
  reviewDefaultHeading,
  ReviewModalTitle,
} from "@ll-web/features/reviews/consts";
import type { ReviewModalTemplate } from "@ll-web/features/reviews/types";
import { getReviewEmailUsers } from "@ll-web/features/reviews/utils/getReviewEmailUsers";

import { reviewWizardAnalyticsMetadata } from "./common/reviewWizardAnalyticsMetadata";
import { reviewWizardToastParams } from "./common/reviewWizardTexts";

type wizardGenerateInternalModalTemplateArgs = {
  users: BrandUserWithProfile[];
  activeUser: User;
  brandName?: string;
  project?: ProjectWithDeliverables;
  page: string;
};

export const wizardGenerateInternalModalTemplate = ({
  brandName,
  users,
  project,
  activeUser,
  page,
}: wizardGenerateInternalModalTemplateArgs): ReviewModalTemplate => {
  return {
    reviewEmailData: wizardGenerateInternalEmail({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: [
          BrandRoleEnum.Owner,
          AccountType.Creative,
          AccountType.Producer,
        ],
      }),
      activeUser,
      project,
      brandName,
    }),
    titleContent: ReviewModalTitle.notifyClientForReview,
    headingContent: reviewDefaultHeading,
    toastParams: reviewWizardToastParams,
    analyticsMetadata: reviewWizardAnalyticsMetadata({
      submitType: ActivityType.CreativeBriefClickedSubmitForReview,
      cancelType: ActivityType.CreativeBriefCanceledSubmittingForReview,
      activeUser,
      page,
      project,
    }),
  };
};

import { ReviewsQueries } from "@ll-web/features/reviews/async/reviewsQueries";
import { reviewsService } from "@ll-web/features/reviews/async/ReviewsService";
import { createMutationHook } from "@ll-web/utils/factories/createMutationHook";

export const useRequestReview = createMutationHook(
  reviewsService.requestReview.bind(reviewsService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ReviewsQueries.getReview({
          reviewKey: params.reviewKey,
        }).queryKey,
      });
    },
  }),
);

export const useApproveReview = createMutationHook(
  reviewsService.approveReview.bind(reviewsService),
  (queryClient) => ({
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: ReviewsQueries.getReview({
          reviewKey: params.reviewKey,
        }).queryKey,
      });
    },
  }),
);

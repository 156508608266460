import { Editor, Element, Transforms } from "slate";

import type { TextEditorElementEnum } from "@ll-web/features/textEditor/types";

export const unwrapInlineElementsOfType = (
  editor: Editor,
  type: TextEditorElementEnum,
) => {
  const elementsToUnwrap = Array.from(
    Editor.nodes(editor, {
      at: [],
      match: (n) => Element.isElement(n) && n.type === type,
    }),
  );

  elementsToUnwrap
    .map((entry) => entry[1])
    .reverse()
    .forEach((path) => {
      Transforms.unwrapNodes(editor, { at: path });
    });
};

import type React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Paper,
  Popover,
  Stack,
  Typography,
  type PopoverVirtualElement,
} from "@mui/material";
import { debounce } from "lodash-es";
import type { Descendant } from "slate";
import { ReactEditor, useSlateSelection, useSlateStatic } from "slate-react";

import { useRemoveConfirmationDialog } from "@ll-web/components/removeConfirmationDialog/useRemoveConfirmationDialog";
import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import type { ProjectCommentAnalyticsMetadata } from "@ll-web/features/projectComments/types";
import { getCurrentCommentNode } from "@ll-web/features/textEditor/comments/commentQueries";
import {
  insertComment,
  unwrapComment,
  updateComment,
} from "@ll-web/features/textEditor/comments/commentTransforms";
import type {
  TextEditorComment,
  TextEditorCommentsPluginConfig,
} from "@ll-web/features/textEditor/comments/types";
import { useToolbarEditModeContext } from "@ll-web/features/textEditor/contexts/ToolbarEditModeContext";
import { getSelectionText } from "@ll-web/features/textEditor/utils/editor";

import { CommentEditorInternalWrapper } from "./CommentEditor/CommentEditorInternalWrapper";
import { CommentEditorProjectWrapper } from "./CommentEditor/CommentEditorProjectWrapper";

type CommentPopoverProps = {
  commentsConfig?: TextEditorCommentsPluginConfig;
  onReadOnlySubmitEditor?: (nodes: Descendant[]) => void;
};

export const CommentPopover = ({
  commentsConfig,
  onReadOnlySubmitEditor,
}: CommentPopoverProps) => {
  const [renderId, setRenderId] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<PopoverVirtualElement | null>(null);
  const { editMode, setEditMode, handleFinishEdit } =
    useToolbarEditModeContext();

  const editor = useSlateStatic();
  const selection = useSlateSelection();

  const isinCommentMode =
    editMode === "newComment" ||
    editMode === "editComment" ||
    editMode === "viewComment";
  const isPopoverOpen = isinCommentMode && !!anchorEl;

  const triggerPopoverRender = useCallback(() => {
    // used to rerender Popover to trigger its inner repositioning mechanism
    setRenderId((id) => id + 1);
  }, []);

  const handleFinish = useCallback(() => {
    setAnchorEl(null);
    triggerPopoverRender();
    editor.deselect();
    onReadOnlySubmitEditor?.(editor.children);
    handleFinishEdit();
  }, [editor, handleFinishEdit, onReadOnlySubmitEditor, triggerPopoverRender]);

  const handleDiscard = useCallback(async () => {
    setAnchorEl(null);
    if (commentsConfig && "metadata" in commentsConfig) {
      activityTracker.log({
        type: ActivityType.WizardOutputConfirmedDiscardingComment,
        metadata: commentsConfig.metadata
          .analyticsMetadata as ProjectCommentAnalyticsMetadata,
      });
    }
    triggerPopoverRender();
    editor.deselect();
    handleFinishEdit();
  }, [commentsConfig, editor, handleFinishEdit, triggerPopoverRender]);

  const handleDiscardCancel = useCallback(() => {
    if (commentsConfig && "metadata" in commentsConfig) {
      activityTracker.log({
        type: ActivityType.WizardOutputCanceledDiscardingComment,
        metadata: commentsConfig.metadata
          .analyticsMetadata as ProjectCommentAnalyticsMetadata,
      });
    }
  }, [commentsConfig]);

  useEffect(() => {
    if (
      (editMode !== "newComment" && editMode !== "viewComment") ||
      isPopoverOpen
    ) {
      return;
    }

    if (editMode === "newComment") {
      const range = selection
        ? ReactEditor.toDOMRange(editor, selection)
        : null;
      const rect = range?.getBoundingClientRect();

      if (!rect) {
        handleDiscard();

        return;
      }

      setAnchorEl({
        nodeType: 1,
        getBoundingClientRect: () => {
          return rect;
        },
      });

      return;
    }

    if (editMode === "viewComment") {
      const commentNode = getCurrentCommentNode(editor);
      const domNode = commentNode
        ? ReactEditor.toDOMNode(editor, commentNode)
        : null;

      if (!domNode) {
        handleDiscard();

        return;
      }

      setAnchorEl({
        nodeType: 1,
        getBoundingClientRect: () => {
          return domNode?.getBoundingClientRect() ?? new DOMRect();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, editMode, isPopoverOpen]);

  const { openRemoveConfirmationModal, removeConfirmationDialogNode } =
    useRemoveConfirmationDialog({
      dialogProps: {
        // As this dialog is not rendered in a portal, this handler blocks clicking on a dialog from calling action on slate text input - and entering edit mode
        onMouseUp: (e: React.MouseEvent) => e.stopPropagation(),
        disablePortal: true,
        confirmationTitle: "Discard the comment",
        confirmationContent: (
          <Stack gap={1.25}>
            <Typography variant="body1" color="text.secondary">
              Do you want to discard the comment?
            </Typography>
          </Stack>
        ),
        removeButtonContent: "Discard",
      },
      onRemove: handleDiscard,
      onCancel: handleDiscardCancel,
    });

  const handleCommentInputChange = useCallback(() => {
    triggerPopoverRender();
  }, [triggerPopoverRender]);

  useEffect(() => {
    triggerPopoverRender();
  }, [editMode, triggerPopoverRender]);

  const handleClose = useCallback(() => {
    if (!isPopoverOpen) {
      return;
    }

    if (editMode === "viewComment") {
      return handleDiscard();
    }

    openRemoveConfirmationModal();
  }, [editMode, isPopoverOpen, handleDiscard, openRemoveConfirmationModal]);

  const handleAddCommentAnchor = useCallback(
    (data: TextEditorComment) => {
      insertComment(editor, data);
    },
    [editor],
  );

  const handleRemoveCommentAnchor = useCallback(() => {
    unwrapComment(editor);
  }, [editor]);

  const handleUpdateCommentAnchor = useCallback(
    (data: TextEditorComment) => {
      updateComment(editor, data);
    },
    [editor],
  );

  const threadId = getCurrentCommentNode(editor)?.comment.id;
  const sharedCommentEditorProps = useMemo(() => {
    if (!isinCommentMode || (!threadId && editMode !== "newComment")) {
      return null;
    }

    return {
      threadId,
      editMode,
      onEditModeChange: setEditMode,
      onCancel: handleDiscard,
      onFinish: handleFinish,
      onThreadLoad: triggerPopoverRender,
      onAddCommentAnchor: handleAddCommentAnchor,
      onRemoveCommentAnchor: handleRemoveCommentAnchor,
      onUpdateCommentAnchor: handleUpdateCommentAnchor,
      quote: getSelectionText(editor),
    };
  }, [
    isinCommentMode,
    threadId,
    editor,
    editMode,
    setEditMode,
    handleDiscard,
    handleFinish,
    triggerPopoverRender,
    handleAddCommentAnchor,
    handleRemoveCommentAnchor,
    handleUpdateCommentAnchor,
  ]);

  return (
    <>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        disablePortal
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
        onClose={handleClose}
        onMouseUp={(e: React.MouseEvent) => {
          // As this popover is not rendered in a portal, this handler blocks clicking on a popover from calling action on slate text input - and entering edit mode
          e.stopPropagation();
        }}
        disableEnforceFocus
        disableRestoreFocus
        disableAutoFocus
        data-render-id={renderId}
      >
        <Paper
          sx={{
            width: 380,
            maxHeight: 370,
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {sharedCommentEditorProps &&
            commentsConfig &&
            "metadata" in commentsConfig && (
              <CommentEditorProjectWrapper
                {...sharedCommentEditorProps}
                commentsConfig={commentsConfig}
                onInputChange={debounce(handleCommentInputChange, 50)}
              />
            )}
          {sharedCommentEditorProps &&
            commentsConfig &&
            "_debugGetComment" in commentsConfig && (
              <CommentEditorInternalWrapper
                {...sharedCommentEditorProps}
                commentsConfig={commentsConfig}
              />
            )}
        </Paper>
      </Popover>
      {removeConfirmationDialogNode}
    </>
  );
};

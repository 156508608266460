import { useGetProjectById } from "@ll-web/features/projects/async/useProjectsQueries";

export const useProject = (id: string) => {
  const query = useGetProjectById({
    id,
  });

  if (!query.isSuccess) {
    throw new Error(
      `Active Project is pending, missing active project id or guard in '${window.location.pathname}'`,
      {
        cause: query.error,
      },
    );
  }

  return { activeProject: query.data };
};

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { projectWizardService } from "@ll-web/features/projectWizard/async/ProjectWizardService";
import type { CharacterCombinedDescriptionParams } from "@ll-web/features/projectWizard/types";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

export const ProjectWizardQueries = createQueryKeys("projectWizard", {
  getVideoSummaryOutputsForProject: createQueryOption(
    projectWizardService.getVideoSummaryOutputsForProject.bind(
      projectWizardService,
    ),
  ),
  getLatestVideoSummaryByVideoId: createQueryOption(
    projectWizardService.getLatestVideoSummaryVideoId.bind(
      projectWizardService,
    ),
  ),
  getLookAndFeelImage: createQueryOption(
    projectWizardService.getLookAndFeelImage.bind(projectWizardService),
  ),
  getLatestCharacterOutputByCharacterAndVideoId: createQueryOption(
    projectWizardService.getLatestCharacterOutputByCharacterAndVideoId.bind(
      projectWizardService,
    ),
  ),
  getWardrobeImage: createQueryOption(
    projectWizardService.getWardrobeImage.bind(projectWizardService),
  ),

  getVideoSummaryOutputs: createQueryOption(
    projectWizardService.getVideoSummaryOutputs.bind(projectWizardService),
  ),
  getLatestInterviewOutputByInterviewId: createQueryOption(
    projectWizardService.getLatestInterviewOutputByInterviewId.bind(
      projectWizardService,
    ),
  ),
  getInterviews: createQueryOption(
    projectWizardService.getInterviews.bind(projectWizardService),
  ),
  getInterviewsOutputs: createQueryOption(
    projectWizardService.getInterviewsOutputs.bind(projectWizardService),
  ),
  getLatestScheduleOutputByProjectId: createQueryOption(
    projectWizardService.getLatestScheduleOutputByProjectId.bind(
      projectWizardService,
    ),
  ),
  getScheduleOutputs: createQueryOption(
    projectWizardService.getScheduleOutputs.bind(projectWizardService),
  ),
  getVoiceoverInputByVideoId: createQueryOption(
    projectWizardService.getVoiceoverInputByVideoId.bind(projectWizardService),
  ),
  getLatestVoiceoverByVideoId: createQueryOption(
    projectWizardService.getLatestVoiceoverByVideoId.bind(projectWizardService),
  ),
  getVoiceoverOutputById: createQueryOption(
    projectWizardService.getVoiceoverOutputById.bind(projectWizardService),
  ),

  getVoiceoverOutputs: createQueryOption(
    projectWizardService.getVoiceoverOutputs.bind(projectWizardService),
  ),
  getVisualsInputsByVideoId: createQueryOption(
    projectWizardService.getVisualsInputsByVideoId.bind(projectWizardService),
  ),
  getLatestVisualsOutputByVideoId: createQueryOption(
    projectWizardService.getLatestVisualsOutputByVideoId.bind(
      projectWizardService,
    ),
  ),
  getVisualsOutputs: createQueryOption(
    projectWizardService.getVisualsOutputs.bind(projectWizardService),
  ),
  getLatestScriptedScriptOutputByVideoId: createQueryOption(
    projectWizardService.getLatestScriptedScriptOutputByVideoId.bind(
      projectWizardService,
    ),
  ),
  getScriptedScriptOutputs: createQueryOption(
    projectWizardService.getScriptedScriptOutputs.bind(projectWizardService),
  ),
  getCharacterCombinedDescription: (
    args: CharacterCombinedDescriptionParams,
  ) => ({
    queryKey: [args.projectId, args.character.id, args.character.videoIds],
    queryFn: ({ signal }) =>
      projectWizardService.getCharacterCombinedDescription(args, { signal }),
  }),
});

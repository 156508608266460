import { Stack } from "@mui/material";

import { useToolbarEditModeContext } from "@ll-web/features/textEditor/contexts/ToolbarEditModeContext";
import { LinkButton } from "@ll-web/features/textEditor/richtext/components/LinkButton";
import { LinkDialog } from "@ll-web/features/textEditor/richtext/components/LinkDialog";
import type { TextEditorConfig } from "@ll-web/features/textEditor/types";

type RichtextSelectionToolbarProps = {
  config?: TextEditorConfig;
};

export const RichtextSelectionToolbar = ({
  config,
}: RichtextSelectionToolbarProps) => {
  const { editMode, handleFinishEdit } = useToolbarEditModeContext();
  const isDialogOpen = ["editLink", "addLink"].includes(editMode);

  const handleCloseDialog = () => {
    handleFinishEdit();
  };

  return (
    config?.plugins?.richText && (
      <>
        <Stack
          sx={{
            zIndex: 10,
            width: "100%",
            justifyContent: "flex-start",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            mb: 1,
          }}
        >
          <LinkButton />
        </Stack>
        <LinkDialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
      </>
    )
  );
};

import type {
  ProjectDataWithId,
  ProjectHeroVideo,
  ProjectWithDeliverablesAndBrand,
} from "@ll-web/features/projects/types";

import type { ActivityType, ProjectMetadata, VideoMetadata } from "./events";
import { EventChannel, type TrackingEvent } from "./types";

const activityTypeSegmentSeparator = ".";

export const buildEventName = (type: ActivityType, separator: string): string =>
  type.split(activityTypeSegmentSeparator).join(separator);

export const isEventOnChannel = (
  event: TrackingEvent,
  channelType: EventChannel,
) => {
  if (event.channel === EventChannel.All) {
    return true;
  }

  return Array.isArray(event.channel)
    ? event.channel.includes(channelType)
    : event.channel === channelType;
};

export const mapProjectToAnalytics = (
  project: Pick<
    ProjectWithDeliverablesAndBrand | ProjectDataWithId,
    "id" | "title" | "style"
  >,
) => {
  return {
    projectId: project.id,
    projectName: project.title,
    videoStyle: project.style,
  } satisfies ProjectMetadata;
};

export const mapProjectsToAnalytics = (
  projects: Pick<
    ProjectWithDeliverablesAndBrand | ProjectDataWithId,
    "id" | "title" | "style"
  >[],
) => {
  return {
    projects: projects.map(
      (project) =>
        ({
          projectId: project.id,
          projectName: project.title,
          videoStyle: project.style,
        }) satisfies ProjectMetadata,
    ),
  };
};

export const mapVideoToAnalytics = (
  video: Pick<ProjectHeroVideo, "id" | "type">,
) => {
  return {
    videoId: video.id,
    videoType: video.type,
  } satisfies VideoMetadata;
};

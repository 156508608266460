import type { SetOptional } from "type-fest";

import type { ActivityEventsMetadata, ActivityType } from "./events";

export enum EventChannel {
  All,
  Analytics,
  EngagementTriggers,
}

export type TrackingEventMetadata = {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | undefined
    | string[]
    | Record<string, unknown>;
};

type GenericTrackingEvent = {
  type: ActivityType;
  channel: EventChannel | EventChannel[];
  metadata: TrackingEventMetadata;
};

export type TrackingEvent = GenericTrackingEvent;

export type TrackingEventInput = SetOptional<
  TrackingEvent,
  "channel" | "metadata"
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MakeEvent<T extends ActivityType, M extends TrackingEventMetadata> = {
  type: T;
  metadata: M;
};

export type ActivityTypesWithMetadata = keyof ActivityEventsMetadata;

type AllEventsWithMetadata = {
  [K in keyof ActivityEventsMetadata]: {
    type: K;
    metadata: ActivityEventsMetadata[K];
  };
}[ActivityTypesWithMetadata];

export type ActivityTypesWithoutMetadata = Exclude<
  ActivityType,
  ActivityTypesWithMetadata
>;

type EventTypeWithoutMetadata = {
  type: ActivityTypesWithoutMetadata;
};

export type TypedTrackingEvent = (
  | AllEventsWithMetadata
  | EventTypeWithoutMetadata
) &
  Omit<TrackingEventInput, "type" | "metadata">;

export type AnalyticsTrigger<
  T extends Record<string, unknown> | void = Record<string, unknown> | void,
> = (metadata: T) => void;

import { useParams, useSearchParams } from "react-router-dom";

import { useFindProjectsByProperty } from "@ll-web/features/internalProjects/async/useInternalProjectsQueries";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { assertDefined } from "@ll-web/utils/types/types";

export const useActiveProposalProject = (): {
  activeProjects: ProjectWithDeliverables[];
  isPending: boolean;
} => {
  const proposalId = useActiveProposalId();
  const query = useFindProjectsByProperty(["proposalId", proposalId]);

  return {
    activeProjects: query.data ?? [],
    isPending: query.isPending,
  };
};

export const useActiveProposalId = (): string => {
  const pathParams = useParams();
  const [queryParams] = useSearchParams();

  const proposalId = pathParams.proposalId ?? queryParams.get("proposalId");
  assertDefined(proposalId, "Missing active project id in path or query");

  return proposalId;
};

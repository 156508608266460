import { LEMONLIGHT_WORKSPACE_DOMAIN } from "@ll-web/consts/hero";
import {
  InternalAccountTypes,
  type AccountType,
} from "@ll-web/features/auth/types";
import { extractDomainFromEmail } from "@ll-web/utils/helpers/helpers";

export function isUserInternal(user: { accountType?: AccountType }) {
  return user.accountType && InternalAccountTypes.includes(user.accountType);
}

export function isLemonlightEmployee({ email }: { email: string }) {
  return extractDomainFromEmail(email) === LEMONLIGHT_WORKSPACE_DOMAIN;
}

import { useEffect } from "react";

import * as Sentry from "@sentry/react";

import { useAnyUser } from "@ll-web/core/hooks/useAnyUser";
import { useOptionalActiveOrganization } from "@ll-web/features/organizations/hooks/useActiveOrganization";

export function useSentrySyncContext() {
  const activeUser = useAnyUser();
  const { activeOrganization } = useOptionalActiveOrganization();

  useEffect(() => {
    if (activeUser) {
      Sentry.setUser({
        id: activeUser.id,
        email: activeUser.email,
        username:
          `${activeUser.firstName ?? ""} ${activeUser.lastName ?? ""}`.trim(),
        accountType: activeUser.accountType,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [activeUser]);

  useEffect(() => {
    if (activeOrganization) {
      Sentry.setTag("organization_id", activeOrganization.id);
      Sentry.setContext("Organization", {
        id: activeOrganization.id,
        name: activeOrganization.name,
        domain: activeOrganization.domain,
      });
    } else {
      Sentry.setTag("organization_id", null);
      Sentry.setContext("Organization", null);
    }
  }, [activeOrganization]);
}

import { FormControl, FormHelperText, InputLabel, Stack } from "@mui/material";

import {
  TextEditor,
  type TextEditorProps,
} from "@ll-web/features/textEditor/TextEditor";

type TextEditorInlineProps = TextEditorProps & {
  error?: string;
  label?: string;
};

export const TextEditorInline = ({
  error,
  label,
  ...props
}: TextEditorInlineProps) => {
  return (
    <FormControl error={!!error} variant="outlined" fullWidth>
      <Stack
        sx={{
          backgroundColor: "background.paper",
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          "&:hover": {
            borderColor: "text.secondary",
          },
          "&:focus-within": {
            borderColor: error ? "error.main" : "primary.main",
            outline: "1px solid",
            outlineColor: error ? "error.main" : "primary.main",

            "& > .custom-label": {
              color: error ? "error.main" : "primary.main",
            },
          },
          p: 1.5,
          ...(!!error && {
            borderColor: "error.main",
          }),
          position: "relative",
        }}
      >
        {label && (
          <InputLabel
            shrink
            className="custom-label"
            sx={{
              position: "absolute",
              top: 0,
              left: -4,
              px: 1,
              backgroundColor: "background.paper",
              "&:focus-within": {
                color: "text.primary",
              },
            }}
          >
            {label}
          </InputLabel>
        )}
        <TextEditor {...props} />
      </Stack>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

import { heroHttpClient } from "@ll-web/core/api/HeroHttpClient";
import type {
  GetProjectTimelineParams,
  ProjectTimeline,
  TimelineAdjustment,
  UpdateProjectTimelineItemDate,
} from "@ll-web/features/projectTimeline/types";

class ProjectTimelineService {
  async getTimeline(args: GetProjectTimelineParams): Promise<ProjectTimeline> {
    return await heroHttpClient.unwrappedHttpRequest<ProjectTimeline>({
      config: {
        method: "GET",
        url: `/v1/project-timeline/${args.projectId}`,
      },
    });
  }

  async createTimelineAdjustment({
    projectId,
    ...args
  }: UpdateProjectTimelineItemDate): Promise<TimelineAdjustment> {
    return await heroHttpClient.unwrappedHttpRequest<TimelineAdjustment>({
      config: {
        method: "PUT",
        url: `/v1/project-timeline/${projectId}/adjustments`,
        data: args,
      },
    });
  }
}

export const projectTimelineService = new ProjectTimelineService();

import type { ReactNode } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type ButtonProps,
  type DialogProps,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";

export type RemoveConfirmationDialogProps = DialogProps & {
  confirmationTitle: ReactNode;
  confirmationContent: ReactNode;
  removeButtonContent?: string;
  close: () => void;
  onRemove: () => Promise<void>;
  confirmButtonColor?: ButtonProps["color"];
};

export const RemoveConfirmationDialog = ({
  confirmationTitle,
  confirmationContent,
  removeButtonContent = "Remove",
  close,
  onRemove,
  confirmButtonColor = "error",
  ...dialogProps
}: RemoveConfirmationDialogProps) => {
  const { mutateAsync: doRemove, isPending } = useMutation({
    mutationFn: onRemove,
    meta: {
      supressErrorToast: true,
    },
  });

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle sx={{ py: 2, px: 3 }}>{confirmationTitle}</DialogTitle>
      <DialogContent sx={{ py: 0 }}>{confirmationContent}</DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          onClick={close}
          variant="outlined"
          color="inherit"
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button
          onClick={() => doRemove()}
          variant="contained"
          color={confirmButtonColor}
          disabled={isPending}
        >
          {removeButtonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useCallback, useMemo, useState } from "react";

import { toast } from "react-toastify";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { NotifyForReviewModal } from "@ll-web/features/reviews/components/NotifyForReviewModal";
import { defaultReviewToastParams } from "@ll-web/features/reviews/consts/defaultReviewToastParams";
import type {
  ReviewEmailData,
  ReviewModalTemplate,
} from "@ll-web/features/reviews/types";
import { UsersQueries } from "@ll-web/features/users/async/useUsersQueries";
import { useBatchQuery } from "@ll-web/utils/hooks/useBatchQueries";
import { defined, type Nullable } from "@ll-web/utils/types/types";

type UseNotifyForReviewDialogArgs<T> = {
  onSubmit: (payload: ReviewEmailData) => Promise<void>;
  onCancel?: VoidFunction;
  onOpen?: (args?: T) => void;
  reviewModalTemplate: ReviewModalTemplate;
  brandId: Nullable<string>; // undefined during loading
  isLoading: boolean;
};

export const useNotifyForReviewDialog = <T = unknown,>({
  onSubmit,
  onCancel,
  onOpen,
  reviewModalTemplate: { toastParams, analyticsMetadata, ...reviewModalProps },
  brandId,
  isLoading,
}: UseNotifyForReviewDialogArgs<T>) => {
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);

  const defaultUsersQuery = useBatchQuery(
    reviewModalProps.reviewEmailData.recipientUserIds.map((userId) =>
      UsersQueries.getById({ userId }),
    ),
  );

  const defaultReceiversData = useMemo(() => {
    return defaultUsersQuery.data?.filter(defined) ?? [];
  }, [defaultUsersQuery.data]);

  const openNotifyModal = useCallback(
    (args?: T) => {
      onOpen?.(args);
      setIsNotifyModalOpen(true);
    },
    [onOpen],
  );

  const handleConfirm = useCallback(
    async (payload: ReviewEmailData) => {
      if (analyticsMetadata?.confirm) {
        activityTracker.log(analyticsMetadata?.confirm);
      }

      await onSubmit(payload);
      setIsNotifyModalOpen(false);
    },
    [analyticsMetadata, onSubmit],
  );

  const handleCancel = useCallback(() => {
    if (analyticsMetadata?.cancel) {
      activityTracker.log(analyticsMetadata?.cancel);
    }

    setIsNotifyModalOpen(false);
    onCancel?.();
  }, [analyticsMetadata, onCancel]);

  const onSubmitForReview = useCallback(
    async (payload: ReviewEmailData) => {
      await toast.promise(
        handleConfirm(payload),
        toastParams ?? defaultReviewToastParams,
      );
    },
    [toastParams, handleConfirm],
  );

  const notifyForReviewDialogNode = useMemo(() => {
    return (
      <NotifyForReviewModal
        {...reviewModalProps}
        onSubmitForReview={onSubmitForReview}
        open={isNotifyModalOpen}
        close={handleCancel}
        defaultReceiversData={defaultReceiversData}
        brandId={brandId}
        isLoading={!!isLoading || defaultUsersQuery.isFetching}
      />
    );
  }, [
    onSubmitForReview,
    isNotifyModalOpen,
    handleCancel,
    defaultUsersQuery.isFetching,
    defaultReceiversData,
    reviewModalProps,
    brandId,
    isLoading,
  ]);

  return useMemo(
    () => ({
      openNotifyModal,
      notifyForReviewDialogNode,
      isNotifyModalOpen,
    }),
    [openNotifyModal, notifyForReviewDialogNode, isNotifyModalOpen],
  );
};

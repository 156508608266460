import { Element, Range, Transforms, type Editor } from "slate";

import { TextEditorElementEnum } from "@ll-web/features/textEditor/types";
import { unwrapInlineElementsOfType } from "@ll-web/features/textEditor/utils/transforms";

import {
  getCurrentCommentNode,
  getCurrentCommentPath,
  isCommentActive,
} from "./commentQueries";
import type { CommentElement, TextEditorComment } from "./types";

export const updateComment = (
  editor: Editor,
  updatePayload: Partial<TextEditorComment>,
) => {
  const isCollapsed = !!editor.selection && Range.isCollapsed(editor.selection);

  if (isCollapsed) {
    return;
  }

  const currentCommentNode = getCurrentCommentNode(editor);
  const currentCommentLocation = getCurrentCommentPath(editor);

  if (!currentCommentNode) {
    return;
  }

  const updatedCommentNode: CommentElement = {
    ...currentCommentNode,
    comment: {
      ...currentCommentNode.comment,
      ...updatePayload,
    },
  };

  Transforms.setNodes(editor, updatedCommentNode, {
    at: currentCommentLocation,
  });
};

export const unwrapComment = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      Element.isElement(n) && n.type === TextEditorElementEnum.Comment,
  });
};

const wrapComment = (editor: Editor, comment: TextEditorComment) => {
  const isCollapsed = !!editor.selection && Range.isCollapsed(editor.selection);

  // update comment
  if (isCollapsed) {
    const currentCommentNode = getCurrentCommentNode(editor);
    const currentCommentLocation = getCurrentCommentPath(editor);

    if (!currentCommentNode) {
      return;
    }

    const node: CommentElement = {
      ...currentCommentNode,
      comment,
    };

    Transforms.setNodes(editor, node, {
      at: currentCommentLocation,
    });

    return;
  }

  // new comment
  const node: CommentElement = {
    type: TextEditorElementEnum.Comment,
    comment,
    children: [],
  };

  if (isCommentActive(editor)) {
    unwrapComment(editor);
  }

  Transforms.wrapNodes(editor, node, { split: true });
  Transforms.collapse(editor, { edge: "end" });
};

export const insertComment = (editor: Editor, comment: TextEditorComment) => {
  if (editor.selection) {
    wrapComment(editor, comment);
  }
};

export const unwrapAllComments = (editor: Editor) => {
  unwrapInlineElementsOfType(editor, TextEditorElementEnum.Comment);
};

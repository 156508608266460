import type { Descendant } from "slate";

import type { TextEditorInputType } from "@ll-web/features/textEditor/types";

import { slateToHtml } from "./html";
import { slateToPseudoMarkdown } from "./markdown";
import { slateToPlainText } from "./plainText";

export const serializeEditor = (
  nodes: Descendant[],
  type: TextEditorInputType,
) => {
  switch (type) {
    case "pseudoMarkdown":
      return slateToPseudoMarkdown(nodes);
    case "plaintext":
      return slateToPlainText(nodes);
    case "html":
      return slateToHtml(nodes);
    default:
      throw new Error(`Unsupported editor input type: ${type}`);
  }
};

import { createContext, useContext, type PropsWithChildren } from "react";

import { merge } from "lodash-es";

import type { TrackingEventMetadata } from "@ll-web/core/analytics/types";
import { useDeepCompareMemo } from "@ll-web/utils/hooks/useDeepCompare";

type AnalyticsContextValue = {
  metadata: TrackingEventMetadata;
};

const defaultValue: AnalyticsContextValue = {
  metadata: {},
};

const AnalyticsContext = createContext<AnalyticsContextValue>(defaultValue);

AnalyticsContext.displayName = "AnalyticsContext";

type AnalyticsProviderProps = PropsWithChildren & {
  metadata: AnalyticsContextValue["metadata"];
};

export function AnalyticsProvider({
  children,
  metadata,
}: AnalyticsProviderProps) {
  const parentValue = useAnalyticsContext();

  const memoedValue = useDeepCompareMemo<AnalyticsContextValue>(
    () => merge({}, parentValue, { metadata } satisfies AnalyticsContextValue),
    [metadata, parentValue],
  );

  return (
    <AnalyticsContext.Provider value={memoedValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalyticsContext(): AnalyticsContextValue {
  return useContext(AnalyticsContext) ?? defaultValue;
}

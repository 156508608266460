import {
  slateToHtml as convertSlateToHtml,
  slateToHtmlConfig,
} from "@slate-serializers/html";
import { type Descendant } from "slate";

export const slateToHtml = (nodes: Descendant[]) => {
  return convertSlateToHtml(nodes, {
    ...slateToHtmlConfig,
    convertLineBreakToBr: true,
  });
};

import { IconButton, styled } from "@mui/material";

// Custom<IconButton> https://www.figma.com/design/Ou7RmFBoNfHYu6LP06dW6h/Lemonlight-Material-UI-5.16.0?node-id=2036-27567&m=dev
export const CustomIconButton = styled(IconButton)(({ theme, color }) => {
  const bgColors = theme.palette.customIconColors;

  return {
    color: color ? bgColors[color].iconColor : bgColors.default.iconColor,
    backgroundColor: color ? bgColors[color].bgColor : bgColors.default.bgColor,
    "&.Mui-disabled": {
      backgroundColor:
        !color || bgColors[color].bgColor
          ? theme.palette.action.disabledBackground
          : "none",
    },
    "&:not(.Mui-disabled):hover": {
      backgroundColor: color && bgColors[color].hoverBgColor,
    },
    ...(color === "inheritWhite" && {
      "&:not(.Mui-disabled)": {
        color: theme.palette.text.primary,
        backgroundColor: bgColors[color].bgColor,
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    }),
  };
}) as typeof IconButton;

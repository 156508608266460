import {
  Element,
  Node,
  Transforms,
  type Descendant,
  type Editor,
  type Text,
} from "slate";

import {
  TextEditorElementEnum,
  type ParagraphElement,
} from "@ll-web/features/textEditor/types";

export const withComments = (editor: Editor): Editor => {
  const { getFragment, isInline, isSelectable, normalizeNode } = editor;

  editor.isInline = (element) => {
    return element.type === TextEditorElementEnum.Comment
      ? true
      : isInline(element);
  };

  editor.isSelectable = (element) => {
    return element.type === TextEditorElementEnum.Comment
      ? true
      : isSelectable(element);
  };

  editor.normalizeNode = ([node, path]) => {
    if (
      Element.isElement(node) &&
      node.type === TextEditorElementEnum.Comment
    ) {
      const hasNonEmptyText = node.children.some(
        (child) => Node.string(child).length > 0,
      );

      if (!hasNonEmptyText) {
        Transforms.removeNodes(editor, { at: path });

        return;
      }
    }

    normalizeNode([node, path]);
  };

  editor.getFragment = () => {
    const fragment = getFragment();

    const transformNode = (node: Descendant) => {
      if (
        Element.isElement(node) &&
        node.type === TextEditorElementEnum.Comment
      ) {
        const element: ParagraphElement = {
          type: TextEditorElementEnum.Paragraph,
          children: node.children,
        };

        return element;
      }

      if (Element.isElement(node)) {
        const element: Text = { text: Node.string(node) };

        return element;
      }

      return node;
    };

    return fragment.map(transformNode);
  };

  return editor;
};

import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";
import type { SetRequired } from "type-fest";

import { DEFAULT_REQUEST_TIMEOUT } from "./consts";

const axiosInstance = axios.create({
  timeout: DEFAULT_REQUEST_TIMEOUT,
  headers: { Accept: "application/json" },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export type RequestConfig = Omit<AxiosRequestConfig, "method"> & {
  method: HttpMethod;
};

export class HttpClient {
  public async httpRequest<T>({
    config,
  }: {
    config: SetRequired<RequestConfig, "baseURL">;
  }): Promise<AxiosResponse<T>> {
    const axiosResponse = await axiosInstance.request(config);

    return axiosResponse;
  }

  public getFilenameFromResponse(response: AxiosResponse<Blob>) {
    const filename = response.headers["content-disposition"]
      ?.split("filename=")[1]
      ?.slice(1, -1);

    if (!filename) {
      throw new Error("Response is missing filename");
    }

    return filename;
  }
}

export const httpClient = new HttpClient();

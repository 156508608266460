import { type User } from "@ll-web/features/auth/types";
import { ProjectStyleEnum } from "@ll-web/features/projects/enums";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { linkToCallSheet } from "@ll-web/features/projectSpace/pages/CallSheets/utils/linkToCallSheet";
import { linkToCreativeDeck } from "@ll-web/features/projectSpace/pages/CreativeDeck/utils/linkToCreativeDeck";
import type { ReviewEmailTemplateArgs } from "@ll-web/features/reviews/types";
import {
  emailGreeting,
  emailSignature,
  htmlEmailBody,
} from "@ll-web/utils/helpers/emails";
import { getPersonFullName } from "@ll-web/utils/helpers/helpers";
import { propertiesDefined, type Nullable } from "@ll-web/utils/types/types";

export const wizardGenerateExternalEmail = ({
  project,
  activeUser,
  reviewEmailTemplateUsers,
}: ReviewEmailTemplateArgs<{
  project?: ProjectWithDeliverables;
  activeUser: User;
}>) => {
  const requiredBodyParams = {
    projectName: project?.title,
    projectId: project?.id,
    projectStyle: project?.style,
  };

  return {
    recipientUserIds: reviewEmailTemplateUsers.recipientUserIds,
    title: `Creative Brief edited/submitted for review${requiredBodyParams.projectName ? ` for ${requiredBodyParams.projectName}` : ""}`,
    body: emailBody({
      requiredBodyParams,
      activeUser,
      reviewEmailTemplateUsers,
    }),
  };
};

const emailBody = ({
  requiredBodyParams,
  reviewEmailTemplateUsers: { defaultRecipient },
  activeUser,
}: ReviewEmailTemplateArgs<
  {
    requiredBodyParams: {
      projectName: Nullable<string>;
      projectId: Nullable<string>;
      projectStyle: Nullable<string>;
    };
  } & {
    activeUser: User;
  }
>) => {
  if (!propertiesDefined(requiredBodyParams)) {
    return "";
  }

  const creativeDeckLink = linkToCreativeDeck(requiredBodyParams.projectId);
  const callSheetLink =
    requiredBodyParams.projectStyle === ProjectStyleEnum.DocStyle
      ? linkToCallSheet(requiredBodyParams.projectId)
      : null;

  return htmlEmailBody(`${emailGreeting("Hi", defaultRecipient?.user?.firstName)}
<br><br>I have reviewed the Creative Brief for ${requiredBodyParams.projectName} and applied some edits/comments to it.
<br>Please take a moment to check the details.
<br><br>You can view them here:
<br><a href="${creativeDeckLink}">Creative Deck</a>${callSheetLink ? `<br><a href="${callSheetLink}">Call Sheet</a>` : ""}
<br><br>${emailSignature("Thanks", getPersonFullName(activeUser))}`);
};

import {
  AccountCircle,
  Business,
  Logout,
  PeopleAlt,
} from "@mui/icons-material";
import {
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  type MenuProps,
  type SvgIconTypeMap,
} from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import { Link } from "react-router-dom";

import { UserAvatar } from "@ll-web/components/User/UserAvatar";
import { Pages } from "@ll-web/core/router/pages";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { useLogOut } from "@ll-web/features/auth/hooks/useLogOut";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { useActiveBrand } from "@ll-web/features/brands/hooks/useActiveBrand";
import { organizationService } from "@ll-web/features/organizations/async/OrganizationsService";
import { useOptionalActiveOrganization } from "@ll-web/features/organizations/hooks/useActiveOrganization";

type MenuIconProps = {
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const MenuIcon = ({ Icon }: MenuIconProps) => {
  return <Icon sx={{ mr: 2, color: (theme) => theme.palette.action.active }} />;
};

export type ActiveUserMenuProps = MenuProps;

export const ActiveUserMenu = ({ ...props }: ActiveUserMenuProps) => {
  const { activeUser } = useActiveUser();
  const { activeOrganization } = useOptionalActiveOrganization();
  const logOut = useLogOut({ activeUser });
  const activeBrandData = useActiveBrand();

  return (
    <Menu {...props} elevation={4}>
      <Stack
        direction="row"
        gap={2}
        sx={{
          minWidth: "240px",
          p: 2,
          pb: 1,
        }}
      >
        <UserAvatar user={activeUser} size={40} />
        <Stack justifyContent="center">
          <Typography variant="body1" fontWeight={700}>
            {activeUser.firstName} {activeUser.lastName}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            onDoubleClick={(ev) =>
              isUserInternal(activeUser) &&
              (ev.altKey || ev.metaKey) &&
              organizationService.promptForBackdoorOrganizationId()
            }
          >
            {isUserInternal(activeUser)
              ? activeOrganization?.name || "No Organization"
              : activeBrandData?.activeBrand?.name}
          </Typography>
        </Stack>
      </Stack>
      <MenuList>
        <Divider sx={{ mb: 1 }} />
        <MenuItem component={Link} to={Pages.SettingsMyAccount}>
          <MenuIcon Icon={AccountCircle} />
          My Account
        </MenuItem>
        {activeBrandData?.activeBrand && (
          <MenuItem component={Link} to={Pages.SettingsTeamGeneral}>
            <MenuIcon Icon={Business} />
            Brand Settings
          </MenuItem>
        )}
        {activeBrandData?.activeBrand && (
          <MenuItem component={Link} to={Pages.SettingsTeamMembers}>
            <MenuIcon Icon={PeopleAlt} />
            Members
          </MenuItem>
        )}
        <Divider />

        <MenuItem onClick={() => logOut()}>
          <MenuIcon Icon={Logout} />
          Log Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

import { useEffect, useState } from "react";

import {
  identify,
  Identify,
  init,
  setUserId,
  track,
  Types,
} from "@amplitude/analytics-browser";
import type { ValidPropertyType } from "@amplitude/analytics-types";

import { APP_CONFIG } from "@ll-web/config/app.config";
import {
  activityTracker,
  analyticsDebugLog,
} from "@ll-web/core/analytics/activityTracker";
import {
  buildEventName,
  isEventOnChannel,
} from "@ll-web/core/analytics/eventUtils";
import { useAnyUser } from "@ll-web/core/hooks/useAnyUser";
import { useOptionalActiveOrganization } from "@ll-web/features/organizations/hooks/useActiveOrganization";

import { EventChannel, type TrackingEvent } from "./types";

const amplitudeSegmentSeparator = " ";

type AmplitudeUserPropertyPayload = {
  property: string;
  value: ValidPropertyType;
};

export const updateAmplitudeUserProperty = (
  payload: AmplitudeUserPropertyPayload | AmplitudeUserPropertyPayload[],
) => {
  const identifyEvent = new Identify();
  Array.isArray(payload)
    ? payload.forEach(({ property, value }) =>
        identifyEvent.set(property, value),
      )
    : identifyEvent.set(payload.property, payload.value);
  identify(identifyEvent);
};

export const useAmplitude = () => {
  const [client] = useState(() => {
    const key = APP_CONFIG.REACT_APP_AMPLITUDE_KEY;
    if (!key) {
      return;
    }

    analyticsDebugLog(`Initializing Amplitude`);

    const instance = init(key, {
      logLevel: Types.LogLevel.Warn,
    });

    return instance;
  });

  const activeUser = useAnyUser();
  const { activeOrganization } = useOptionalActiveOrganization();

  useEffect(() => {
    if (!client) {
      return;
    }
    if (activeUser) {
      analyticsDebugLog(
        `Identifying user ${activeUser.id} ${activeUser.email} in Amplitude`,
      );
      identify(new Identify());
      setUserId(activeUser.id);
      updateAmplitudeUserProperty([
        { property: "email", value: activeUser.email },
        { property: "accountType", value: activeUser.accountType },
        { property: "isActive", value: activeUser.isActive },
      ]);
      if (activeOrganization) {
        analyticsDebugLog(
          `Identifying organization ${activeOrganization.id} ${activeOrganization.name} in Amplitude`,
        );
        updateAmplitudeUserProperty([
          { property: "organizationId", value: activeOrganization.id },
          { property: "organizationName", value: activeOrganization.name },
          {
            property: "organizationDomain",
            value: activeOrganization.domain ?? "",
          },
        ]);
      }
    } else {
      analyticsDebugLog(`Deidentifying user in Amplitude`);
      identify(new Identify());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!client, activeUser, activeOrganization]);

  useEffect(() => {
    if (!client) {
      return;
    }

    return activityTracker.subscribe("Amplitude", (event: TrackingEvent) => {
      // amplitude processes events on analytics channel
      if (!isEventOnChannel(event, EventChannel.Analytics)) {
        return;
      }
      track(
        buildEventName(event.type, amplitudeSegmentSeparator),
        event.metadata,
      );
    });
  }, [client]);
};

import { useMemo, type FC } from "react";

import {
  BoltOutlined,
  FolderOpen,
  ViewListOutlined,
} from "@mui/icons-material";
import { alpha, Drawer, List, ListItem, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import { Pages } from "@ll-web/core/router/pages";
import { useUpdateActiveUserSettings } from "@ll-web/features/auth/userSettings/async/useUserSettingsMutations";

type NavItem = {
  page: Pages;
  label: string;
  icon: FC;
  analyticsActivity?: ActivityType;
};

type SidebarNavigationProps = {
  isOpen: boolean;
};

export const SidebarNavigation = ({ isOpen }: SidebarNavigationProps) => {
  const location = useLocation();
  const flags = useAppFlags();

  const userSettingsMutation = useUpdateActiveUserSettings({
    meta: { supressErrorToast: true },
  });

  const items = useMemo(() => {
    return [
      {
        page: Pages.InternalProjects,
        label: "Projects",
        icon: FolderOpen,
        analyticsActivity: ActivityType.DashboardClickedProjectView,
      },
      {
        page: Pages.InternalContracts,
        label: "Contracts",
        icon: BoltOutlined,
        analyticsActivity: ActivityType.DashboardClickedContractsView,
      },
      ...((flags[FeatureFlagName.CreativeProposals]
        ? [
            {
              page: Pages.InternalProposals,
              label: "Creative Proposal",
              icon: ViewListOutlined,
              analyticsActivity:
                ActivityType.DashboardClickedCreativeProposalsView,
            },
          ]
        : []) satisfies NavItem[]),
    ] satisfies NavItem[];
  }, [flags]);

  const width = 276;

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      component="aside"
      sx={(theme) => {
        // Based on https://mui.com/material-ui/react-drawer/#mini-variant-drawer
        const openedMixin = {
          width,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: "hidden",
        };
        const closedMixin = {
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: "hidden",
          width: `calc(${theme.spacing(8)} + 1px)`,
        };

        return {
          width,
          flexShrink: 0,
          whiteSpace: "nowrap",
          ...(isOpen
            ? {
                "&, & .MuiDrawer-paper": openedMixin,
              }
            : { "&, & .MuiDrawer-paper": closedMixin }),
          ":hover": {
            "& .MuiDrawer-paper": openedMixin,
          },
        };
      }}
    >
      <List
        component="aside"
        sx={(theme) => ({
          py: 11,
          position: "relative",
          ...(isOpen
            ? {
                px: 2,
                transition: theme.transitions.create("padding", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }
            : {
                px: 1.5,
                transition: theme.transitions.create("padding", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }),
          "::after": {
            // cover up protruding labels
            content: '""',
            position: "absolute",
            insetBlock: 0,
            right: 0,
            width: theme.spacing(1.5),
            zIndex: 1,
            background: (theme) => theme.palette.background.default,
          },
        })}
      >
        {items.map((item) => {
          const Icon = item.icon;

          return (
            <ListItem
              key={item.page}
              component={Link}
              to={item.page}
              onClick={() => {
                userSettingsMutation.mutateAsync({
                  internalDashboardDefaultPage: item.page,
                });
                if (item.analyticsActivity) {
                  activityTracker.log(item.analyticsActivity);
                }
              }}
              sx={{
                px: 1,
                py: 1,
                borderRadius: 2,
                display: "flex",
                gap: 1.5,
                transition:
                  "opacity 0.15s ease-in-out, background 0.15s ease-in-out",
                ...(item.page === location?.pathname
                  ? {
                      background: (theme) =>
                        alpha(
                          theme.palette.primary.main,
                          theme.palette.action.selectedOpacity,
                        ),
                    }
                  : {
                      opacity: 0.6,
                      ":hover": {
                        background: (theme) =>
                          alpha(
                            theme.palette.primary.main,
                            theme.palette.action.hoverOpacity,
                          ),
                        opacity: 0.8,
                      },
                    }),
                ":active": {
                  background: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity,
                    ),
                },
              }}
            >
              <Icon
                sx={{
                  color: (theme) => theme.palette.text.primary,
                }}
              />
              <Typography variant="body1" fontWeight={700} color="text.primary">
                {item.label}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

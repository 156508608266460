import dayjs from "dayjs";

import { LS_PREFIX } from "@ll-web/consts/globals";

export const ORGANIZATION_ID_LS_KEY = `${LS_PREFIX}OrganizationId`;
export const ORGANIZATION_BACKDOOR_LS_KEY = `${LS_PREFIX}OrganizationOverride`;

export const ORGANIZATION_HTTP_HEADER = "X-HERO-Organization-ID";

// Temporary ID for backward compatiblity with feature flag
export const LEMONLIGHT_ORGANIZATION_ID = "6746ebf6dc7e3c03db000000";
export const LEMONLIGHT_DEFAULT_ORGANIZATION = {
  id: LEMONLIGHT_ORGANIZATION_ID,
  name: "Lemonlight",
  createdAt: dayjs(),
  updatedAt: dayjs(),
};

export const FALLBACK_ORGANIZATION_NAME = "Lemonlight";

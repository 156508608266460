import type { ActivityType } from "@ll-web/core/analytics/events";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import type { TypedTrackingEvent } from "@ll-web/core/analytics/types";
import type { User } from "@ll-web/features/auth/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";

export const reviewWizardAnalyticsMetadata = ({
  submitType,
  cancelType,
  activeUser,
  project,
  page,
}: {
  submitType: ActivityType;
  cancelType: ActivityType;
  activeUser: User;
  project?: ProjectWithDeliverables;
  page: string;
}) => {
  const metadata = {
    senderRole: activeUser.accountType,
    ...(project ? mapProjectToAnalytics(project) : {}),
    page,
  };

  return {
    confirm: {
      type: submitType,
      metadata,
    } as TypedTrackingEvent,
    cancel: {
      type: cancelType,
      metadata,
    } as TypedTrackingEvent,
  };
};

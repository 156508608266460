import { useCallback } from "react";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { useProjectComments } from "@ll-web/features/projectComments/hooks/useProjectComments";
import type {
  CommentMessageType,
  ProjectCommentAnalyticsMetadata,
  ProjectCommentMetadata,
} from "@ll-web/features/projectComments/types";
import type {
  TextEditorComment,
  TextEditorCommentsPluginProjectConfig,
} from "@ll-web/features/textEditor/comments/types";
import type { TextEditorEditMode } from "@ll-web/features/textEditor/types";
import { assertDefined } from "@ll-web/utils/types/types";

import { CommentEditor } from "./CommentEditor";

type CommentEditorProjectWrapperProps = {
  threadId?: string;
  commentsConfig: TextEditorCommentsPluginProjectConfig;
  onCancel: () => void;
  onFinish: () => void;
  onEditModeChange: (editMode: TextEditorEditMode) => void;
  onInputChange?: (value: string) => void;
  onThreadLoad?: (threadId: string) => void;
  onAddCommentAnchor?: (data: TextEditorComment) => void;
  onRemoveCommentAnchor?: (data: TextEditorComment) => void;
  onUpdateCommentAnchor?: (data: TextEditorComment) => void;
  editMode: TextEditorEditMode;
  quote?: string;
  isInSidebar?: boolean;
  index?: number;
};

export const CommentEditorProjectWrapper = ({
  commentsConfig,
  onEditModeChange,
  onAddCommentAnchor,
  onUpdateCommentAnchor,
  onFinish,
  quote,
  index,
  ...props
}: CommentEditorProjectWrapperProps) => {
  assertDefined(commentsConfig.metadata);
  const { onAdd, onDelete, ...projectCommentsProps } = useProjectComments({
    metadata: commentsConfig.metadata as ProjectCommentMetadata,
  });

  const handleEditStart = useCallback(() => {
    onEditModeChange("editComment");
    activityTracker.log({
      type: ActivityType.WizardOutputClickedEditComment,
      metadata: commentsConfig.metadata
        .analyticsMetadata as ProjectCommentAnalyticsMetadata,
    });
  }, [commentsConfig, onEditModeChange]);

  const handleDelete = useCallback(
    async (data: TextEditorComment & { threadId: string }) => {
      activityTracker.log({
        type: ActivityType.WizardOutputRemovedComment,
        metadata: commentsConfig.metadata
          .analyticsMetadata as ProjectCommentAnalyticsMetadata,
      });
      await onDelete(data);
    },
    [commentsConfig, onDelete],
  );

  const handleCreateThread = useCallback(
    async (
      data: Pick<CommentMessageType, "message"> & {
        userId?: string;
      },
    ) => {
      assertDefined(onAddCommentAnchor);

      activityTracker.log({
        type: ActivityType.WizardOutputLeftComment,
        metadata: commentsConfig.metadata
          .analyticsMetadata as ProjectCommentAnalyticsMetadata,
      });
      const commentData = {
        ...data,
        quote,
      };
      const comment = await onAdd(commentData);
      if (comment) {
        const { id } = comment;
        onAddCommentAnchor({ id });
      }
      onFinish();
    },
    [commentsConfig, quote, onAdd, onFinish, onAddCommentAnchor],
  );

  const handleAddReply = useCallback(
    async (
      data: Pick<CommentMessageType, "threadId" | "message"> & {
        userId?: string;
      },
    ) => {
      activityTracker.log({
        type: ActivityType.WizardOutputRepliedToComment,
        metadata: commentsConfig.metadata
          .analyticsMetadata as ProjectCommentAnalyticsMetadata,
      });

      await onAdd(data);
    },
    [commentsConfig, onAdd],
  );

  const handleUpdateCommentAnchor = useCallback(
    (data: TextEditorComment) => {
      if (data.isResolved === true) {
        activityTracker.log({
          type: ActivityType.WizardOutputResolvedComment,
          metadata: commentsConfig.metadata
            .analyticsMetadata as ProjectCommentAnalyticsMetadata,
        });
      } else if (data.isResolved === false) {
        activityTracker.log({
          type: ActivityType.WizardOutputReopenedComment,
          metadata: commentsConfig.metadata
            .analyticsMetadata as ProjectCommentAnalyticsMetadata,
        });
      }

      onUpdateCommentAnchor?.(data);
    },
    [commentsConfig, onUpdateCommentAnchor],
  );

  return (
    <CommentEditor
      index={index}
      isReadOnly={commentsConfig?.isReadOnly}
      commentsConfig={commentsConfig}
      onEditStart={handleEditStart}
      onAddThread={handleCreateThread}
      onAddReply={handleAddReply}
      onDelete={handleDelete}
      onUpdateCommentAnchor={handleUpdateCommentAnchor}
      onFinish={onFinish}
      onEditModeChange={onEditModeChange}
      {...projectCommentsProps}
      {...props}
    />
  );
};

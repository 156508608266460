import type { TextEditorInputType } from "@ll-web/features/textEditor/types";

import { htmlToSlate } from "./html";
import { pseudoMarkdownToSlate } from "./markdown";
import { plainTextToSlate } from "./plainText";

export const deserializeEditor = (value: string, type: TextEditorInputType) => {
  switch (type) {
    case "pseudoMarkdown":
      return pseudoMarkdownToSlate(value);
    case "plaintext":
      return plainTextToSlate(value);
    case "html":
      return htmlToSlate(value);
    default:
      throw new Error(`Unsupported editor input type: ${type}`);
  }
};

import { type PropsWithChildren } from "react";

import { FormProvider } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";

import { AnalyticsProvider } from "@ll-web/core/analytics/AnalyticsContext";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import type { ProjectFormPageState } from "@ll-web/features/internalProjects/pages/ProjectForm/types";
import { type ProjectFormValues } from "@ll-web/features/internalProjects/projectForm/projectFormSchema";
import { useProjectForm } from "@ll-web/features/internalProjects/projectForm/utils/useProjectForm";
import { useProjectFormAnalytics } from "@ll-web/features/internalProjects/projectForm/utils/useProjectFormAnalytics";

type ProjectFormRouteProviderProps = PropsWithChildren<{
  passedInitialValues?: ProjectFormValues;
}>;

export const ProjectFormRouteProvider = ({
  passedInitialValues,
  children,
}: ProjectFormRouteProviderProps) => {
  const [searchParams] = useSearchParams();
  const passedMsa = searchParams.get("msa");

  const passedValues = (useLocation().state ?? {}) as ProjectFormPageState;

  const initialValues = passedInitialValues || {
    ...passedValues,
    basicInfo: {
      ...passedValues.basicInfo,
      ...(passedMsa ? { msa: passedMsa } : {}),
    },
  };
  const { methods } = useProjectForm({ initialValue: initialValues });
  const { id, title, style } = useProjectFormAnalytics(methods);

  return (
    <AnalyticsProvider metadata={mapProjectToAnalytics({ id, title, style })}>
      <FormProvider {...methods}>{children}</FormProvider>
    </AnalyticsProvider>
  );
};

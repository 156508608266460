import { useCallback, useMemo } from "react";

import { alpha, Stack } from "@mui/material";
import { noop } from "lodash-es";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { useCommentNumberRegister } from "@ll-web/features/projectComments/contexts/CommentNumberRegisterProvider";
import { useHoveredCommentContext } from "@ll-web/features/projectComments/contexts/HoveredCommentContext";
import type {
  ProjectCommentAnalyticsMetadata,
  ProjectCommentMetadata,
} from "@ll-web/features/projectComments/types";
import { CommentEditorProjectWrapper } from "@ll-web/features/textEditor/comments/components/CommentEditor/CommentEditorProjectWrapper";
import type { TextEditorCommentsPluginProjectConfig } from "@ll-web/features/textEditor/comments/types";
import type { TextEditorEditMode } from "@ll-web/features/textEditor/types";

type CommentSidebarThreadProps = {
  threadId: string;
  threadMetadata: ProjectCommentMetadata;
  editMode: TextEditorEditMode;
  onEditModeChange: (editMode: TextEditorEditMode) => void;
  onFinish: () => void;
  showBorderTop?: boolean;
  onThreadLoad: (threadId: string) => void;
};

export const CommentSidebarThread = ({
  threadId,
  threadMetadata,
  editMode,
  onEditModeChange,
  onFinish,
  showBorderTop,
  onThreadLoad,
}: CommentSidebarThreadProps) => {
  const hoveredCommentContext = useHoveredCommentContext({ threadId });
  const { commentNumberRegister } = useCommentNumberRegister();
  const index = commentNumberRegister[threadId];
  const commentsConfig: TextEditorCommentsPluginProjectConfig = useMemo(() => {
    return {
      metadata: threadMetadata,
    };
  }, [threadMetadata]);

  const handleDiscard = useCallback(async () => {
    activityTracker.log({
      type: ActivityType.WizardOutputConfirmedDiscardingComment,
      metadata:
        threadMetadata.analyticsMetadata as ProjectCommentAnalyticsMetadata,
    });
    onFinish();
  }, [threadMetadata.analyticsMetadata, onFinish]);

  return (
    <>
      <Stack
        ref={hoveredCommentContext.threadElementRef}
        sx={{
          ...(hoveredCommentContext.isHovered && {
            background: (theme) =>
              alpha(
                theme.palette.secondary.main,
                theme.palette.action.selectedOpacity,
              ),
          }),

          ...(showBorderTop && {
            borderTop: (theme) => `2px solid ${theme.palette.divider}`,
          }),
        }}
      >
        <CommentEditorProjectWrapper
          index={index}
          threadId={threadId}
          editMode={editMode}
          isInSidebar
          commentsConfig={commentsConfig}
          onEditModeChange={onEditModeChange}
          onCancel={handleDiscard}
          onFinish={onFinish}
          onInputChange={noop}
          onThreadLoad={onThreadLoad}
        />
      </Stack>
    </>
  );
};

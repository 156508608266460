import { type TypographyProps } from "@mui/material";
import type { RenderElementProps } from "slate-react";

import { Element } from "./Element";

export const makeElement =
  (textNodeProps?: TypographyProps) =>
  // eslint-disable-next-line react/display-name
  (props: RenderElementProps) => (
    <Element {...props} textNodeProps={textNodeProps} />
  );

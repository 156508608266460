import type { PartialDeep } from "type-fest";

import { AccountType } from "@ll-web/features/auth/types";
import { BrandRoleEnum } from "@ll-web/features/brands/types";
import { type ContractFormValues } from "@ll-web/features/contracts/components/ContractForm/contractFormSchema";
import type { ContractWithBrand } from "@ll-web/features/contracts/types";

export function mapContractToForm({
  contract,
}: {
  contract: ContractWithBrand;
}): PartialDeep<ContractFormValues> {
  const findUserId = (accountType: AccountType) =>
    contract.brand.team.find(
      (brandUser) => brandUser.user?.accountType === accountType,
    )?.userId;

  return {
    msa: contract.msa,
    brand: {
      id: contract.brand._id,
      name: contract.brand.name,
      website: contract.brand.website,
      owner: contract.brand.team.find(
        (brandUser) => brandUser.role === BrandRoleEnum.Owner,
      )?.userId,
      companyType: contract.brand.companyType,
      accountExecutive: findUserId(AccountType.AccountExecutive),
      producer: findUserId(AccountType.Producer),
      creativeProducer: findUserId(AccountType.Creative) ?? null,
      editor: findUserId(AccountType.Editor) ?? null,
    },
  };
}

export function validateMsa(msa?: string): boolean {
  const regex = /^[a-zA-Z0-9-]+$/;

  if (msa && regex.test(msa)) {
    return true;
  }

  return false;
}

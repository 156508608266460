import { heroHttpClient } from "@ll-web/core/api/HeroHttpClient";
import type { User } from "@ll-web/features/auth/types";
import type {
  CreateUser,
  GetUserByIdArgs,
  UpdateUserDto,
  UsersFindAllQueryDto,
} from "@ll-web/features/users/types";
import type { ByIdParams } from "@ll-web/utils/types/types";

class UsersService {
  async list(args: UsersFindAllQueryDto): Promise<User[]> {
    return await heroHttpClient.unwrappedHttpRequest<User[]>({
      config: {
        method: "GET",
        url: "/v1/users",
        params: args,
      },
    });
  }

  async create(args: CreateUser): Promise<User> {
    return await heroHttpClient.unwrappedHttpRequest<User>({
      config: {
        method: "POST",
        url: "/v1/users",
        data: {
          ...args,
          phoneNumber: args.phoneNumber || undefined,
        },
      },
    });
  }

  async getById(args: GetUserByIdArgs): Promise<User> {
    return await heroHttpClient.unwrappedHttpRequest<User>({
      config: {
        method: "GET",
        url: `/v1/users/${args.userId}`,
      },
    });
  }

  async updateById({
    id,
    ...payload
  }: ByIdParams & UpdateUserDto): Promise<User> {
    return await heroHttpClient.unwrappedHttpRequest<User>({
      config: {
        method: "PATCH",
        url: `/v1/users/${id}`,
        data: payload,
      },
    });
  }
}

export const usersService = new UsersService();

import { AccountType, type User } from "@ll-web/features/auth/types";
import { type BrandUserWithProfile } from "@ll-web/features/brands/types";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { editGeneratedDocExternalEmail } from "@ll-web/features/projectSpace/reviews/emailTemplates/editGeneratedDocExternalEmail";
import type { ReviewEditGeneratedDocsKey } from "@ll-web/features/projectSpace/reviews/reviewGeneratedDocsKeys";
import {
  reviewDefaultHeading,
  ReviewModalTitle,
} from "@ll-web/features/reviews/consts";
import type { ReviewModalTemplate } from "@ll-web/features/reviews/types";
import { getReviewEmailUsers } from "@ll-web/features/reviews/utils/getReviewEmailUsers";

import { reviewEditDocumentsToastParams } from "./common/reviewEditDocsTexts";
import { generatedDocData } from "./common/reviewGeneratedDocsUtils";

type EditGeneratedDocExternalModalTemplateArgs = {
  users: BrandUserWithProfile[];
  project: ProjectWithDeliverables;
  activeUser: User;
  reviewKey: ReviewEditGeneratedDocsKey;
};

export const editGeneratedDocExternalModalTemplate = ({
  reviewKey,
  users,
  project,
  activeUser,
}: EditGeneratedDocExternalModalTemplateArgs): ReviewModalTemplate => {
  const { generatedDocName, generatedDocUrl } = generatedDocData({
    notificationType: reviewKey,
    projectId: project.id,
  });

  return {
    reviewEmailData: editGeneratedDocExternalEmail({
      ...getReviewEmailUsers({
        users,
        recipientsTypes: [AccountType.Producer, AccountType.AccountExecutive],
      }),
      generatedDocName,
      generatedDocUrl,
      project,
      activeUser,
    }),
    titleContent: ReviewModalTitle.notifyProducerForReview,
    headingContent: reviewDefaultHeading,
    toastParams: generatedDocName
      ? reviewEditDocumentsToastParams(generatedDocName)
      : undefined,
  };
};

import { useMemo } from "react";

import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import { useUser } from "@ll-web/features/auth/hooks/useUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { organizationService } from "@ll-web/features/organizations/async/OrganizationsService";
import { LEMONLIGHT_DEFAULT_ORGANIZATION } from "@ll-web/features/organizations/consts";
import type { Organization } from "@ll-web/features/organizations/types";

export function useOptionalActiveOrganization() {
  const flags = useAppFlags();
  const { activeUser } = useUser();

  const activeOrganization = useMemo<Organization | null>(() => {
    if (!activeUser || !isUserInternal(activeUser)) {
      return null;
    }

    if (!flags[FeatureFlagName.Hero]) {
      organizationService.setBackdoorOrganizationId(
        LEMONLIGHT_DEFAULT_ORGANIZATION.id,
      );

      return LEMONLIGHT_DEFAULT_ORGANIZATION;
    }

    const initialOrganizationId = organizationService.getOrganizationId();
    const organization = activeUser.organizations?.find(
      (org) => org.organization.id === initialOrganizationId,
    )?.organization;

    if (!organization) {
      if (
        initialOrganizationId &&
        organizationService.isBackdoorOrganization()
      ) {
        return organizationService.makeFakeOrganization(initialOrganizationId);
      }

      const firstOrganization = activeUser.organizations?.[0]?.organization;
      if (firstOrganization) {
        organizationService.setOrganizationId(firstOrganization.id);

        return firstOrganization;
      } else {
        organizationService.setOrganizationId(null);

        return null;
      }
    }

    return organization;
  }, [activeUser, flags]);

  return {
    activeOrganization,
  };
}

/**
 * Use only on internal pages with the OrganizationGuard
 */
export function useActiveOrganization() {
  const { activeOrganization } = useOptionalActiveOrganization();

  if (!activeOrganization) {
    throw new Error("Active organization is missing");
  }

  return {
    activeOrganization,
  };
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useRef, type DependencyList } from "react";

import { isEqual } from "lodash-es";

function useDeepCompareMemoize(dependencies: DependencyList) {
  const dependenciesRef = useRef<DependencyList>(dependencies);
  const signalRef = useRef<boolean>(true);

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
    signalRef.current = !signalRef.current;
  }

  return useMemo(() => dependenciesRef.current, [signalRef.current]);
}

export function useDeepCompareMemo<T>(
  factory: () => T,
  dependencies: DependencyList,
) {
  return useMemo(factory, useDeepCompareMemoize(dependencies));
}

// That's how react defines it
// eslint-disable-next-line @typescript-eslint/ban-types
export function useDeepCompareCallback<T extends Function>(
  callback: T,
  dependencies: DependencyList,
) {
  return useCallback(callback, useDeepCompareMemoize(dependencies));
}

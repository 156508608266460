import { EmailOutlined, Logout } from "@mui/icons-material";
import {
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  type MenuProps,
  type SvgIconTypeMap,
} from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";

import { HERO_SUPPORT_EMAIL } from "@ll-web/consts/hero";
import { useActiveCreator } from "@ll-web/creator/auth/hooks/useActiveCreator";
import { useCreatorLogOut } from "@ll-web/creator/auth/hooks/useLogOut";
import { ActiveCreatorAvatar } from "@ll-web/creator/components/ActiveCreator/ActiveCreatorAvatar";
import { useResponsiveView } from "@ll-web/utils/hooks/useResponsiveView";

type MenuIconProps = {
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const MenuIcon = ({ Icon }: MenuIconProps) => {
  return <Icon sx={{ mr: 2, color: (theme) => theme.palette.action.active }} />;
};

export type ActiveCreatorMenuProps = MenuProps;

export const ActiveCreatorMenu = ({ ...props }: ActiveCreatorMenuProps) => {
  const { activeCreator } = useActiveCreator();
  const logOut = useCreatorLogOut({ activeCreator });
  const { isMobile } = useResponsiveView();

  return (
    <Menu {...props} elevation={4}>
      <Stack
        direction="row"
        gap={2}
        sx={{
          minWidth: "240px",
          p: 2,
          pb: 1,
        }}
      >
        <ActiveCreatorAvatar size={40} />
        <Stack justifyContent="center">
          <Typography variant="body1" fontWeight={700}>
            {activeCreator.firstName} {activeCreator.lastName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {activeCreator.email}
          </Typography>
        </Stack>
      </Stack>
      <MenuList>
        <Divider sx={{ mb: 1 }} />
        {isMobile && (
          <MenuItem
            /**
             * @see https://stackoverflow.com/a/78851774
             */
            {...{ component: "a" }}
            href={`mailto:${HERO_SUPPORT_EMAIL}`}
          >
            <MenuIcon Icon={EmailOutlined} />
            Get Assistance
          </MenuItem>
        )}
        <MenuItem onClick={() => logOut()}>
          <MenuIcon Icon={Logout} />
          Log Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

import type { PropsWithChildren } from "react";

import { Navigate } from "react-router-dom";

import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";

export const CreativeProposalFeatureFlagGuard = ({
  children,
}: PropsWithChildren) => {
  const flags = useAppFlags();
  if (!flags[FeatureFlagName.CreativeProposals]) {
    return <Navigate to="/" replace />;
  }

  return children;
};
